export const MODULE_REQUEST = 'MODULE_REQUEST';
export const MODULE_FETCH_SUCCESS = 'MODULE_FETCH_SUCCESS';
export const MODULE_FETCH_FAILURE = 'MODULE_FETCH_FAILURE'

export const GET_MODULE_REQUEST = 'GET_MODULE_REQUEST';
export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_FAILURE = 'GET_MODULE_FAILURE';


export const VIDEO_DURATION_UPDATE_REQUEST = 'VIDEO_DURATION_UPDATE_REQUEST'
export const VIDEO_DURATION_UPDATE_SUCCESS = 'VIDEO_DURATION_UPDATE_SUCCESS'
export const VIDEO_DURATION_UPDATE_FAILURE = 'VIDEO_DURATION_UPDATE_FAILURE'