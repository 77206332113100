import React, { useEffect, useState } from "react";
import "../Style/Home.css";
import { connect } from "react-redux";
import { newsfeedrequest } from "../../Container/NewsFeed/action";
import { BiLike } from "react-icons/bi";
import { IoShareSocialOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Modal from "../../common/Modal";
import BannerTwo from "../Banner/BannerTwo";
import { FaClock } from "react-icons/fa";
// import { FaUser } from "react-icons/fa";

function Home({ newsfeedrequest, newsFeed, Bannerdata }) {
  const [expandedNews, setExpandedNews] = useState({});
  const [token, settoken] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modulesdata, setmodulesdata] = useState([]);
  const [coursedata, setcoursedata] = useState([]);
  const [newsfeeddata, setnewsfeed] = useState([]);
  const [webinarsdata, setwebinarsfeed] = useState([]);
  const [programdata, setprogramdata] = useState([]);
  const [Todo, setTodo] = useState([]);
  const navigate = useNavigate();

  // console.log("modulesdata", modulesdata);

  useEffect(() => {
    newsfeedrequest();
    const authtoken = localStorage.getItem("token");
    if (authtoken !== null) {
      settoken(true);
    }
    if (Bannerdata && Bannerdata.length > 0) {
      setmodulesdata(Bannerdata[0].modules || []);
      setcoursedata(Bannerdata[0].courses || []);
      setnewsfeed(Bannerdata[0].feeds || []);
      setwebinarsfeed(Bannerdata[0].webinars || []);
      setprogramdata(Bannerdata[0].programsProgress || []);
      setTodo(Bannerdata[0].programTask || []);
    }
  }, [newsfeedrequest, token, Bannerdata]);

  // console.log("modulesdata", modulesdata);

  const renderProgressCircle = (percentage) => {
    const radius = 25;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (circumference * percentage) / 100;

    return (
      <svg width="80" height="80">
        <circle
          cx="40"
          cy="40"
          r={radius}
          stroke="#e0e0e0"
          strokeWidth="5"
          fill="none"
        />
        <circle
          cx="40"
          cy="40"
          r={radius}
          stroke="#00FF00"
          strokeWidth="5"
          fill="none"
          style={{ strokeDasharray: circumference, strokeDashoffset }}
        />
      </svg>
    );
  };

  const toggleReadMore = (index) => {
    setExpandedNews((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleViewAll = (params, id) => {
    console.log('id---->', id)
    if (!token) {
      setIsModalOpen(true);
      return;
    }
    if (id) {
      navigate(`${params}?id=${id}`);
    } else {
      navigate(`${params}`);
    }
  };

  const onclickbutton = (params) => {
    navigate(params);
  };

  const cancelLogout = () => {
    setIsModalOpen(false);
  };

  const onclickLike = () => {
    if (!token) {
      setIsModalOpen(true);
      return;
    }
  };

  const onclickshare = () => {
    if (!token) {
      setIsModalOpen(true);
      return;
    }
  };

  return (
    <>
      {token && (
        <div className="home-container">
          <div className="card program">
            <h2 className="program-header">To-Do List</h2>
            <div className="todo-list">
              {Todo &&
                Todo.map((task, index) => (
                  <div className="todo-card task-with-line" key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="task-header">
                        <div className="badge"></div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            className="dot"
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "orange" : "gray",
                            }}
                          ></span>
                          <span>{task.program_name}</span>
                        </div>
                        {/* <div className="avatar">{task.program_name}</div> */}
                        <span className="task-title">{`${task.content_type}-${task.content_name}`}</span>
                      </div>
                      <div style={{ width: "30%" }}>
                        <button
                          className="todo-button"
                          onClick={() => {
                            handleViewAll(task.route, task.contentId);
                          }}
                        >
                          To-Do
                        </button>
                      </div>
                    </div>
                    <div className="task-details">
                      <span className="status">{task.status}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="card program">
            <h2 className="program-header">Programs</h2>
            {programdata &&
              programdata.map((program, index) => (
                <div className="task-progress-card" key={index}>
                  <div
                    className="progress-circle-container"
                    onClick={() => {
                      navigate("program");
                    }}
                  >
                    <div className="progress-circle">
                      {renderProgressCircle(program.completion_percentage)}
                      <div className="progress-percentage">
                        {Math.round(program.completion_percentage)}%
                      </div>
                    </div>
                    <div className="task-counter">
                      <span style={{ fontWeight: "bold" }}>
                        {`${program.program_name}- ${program.program_type}`}
                      </span>
                      <span>
                        You have {program.pending_flows} tasks left to do
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {webinarsdata.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              Webinar
            </span>
          </div>
          {modulesdata.length > 2 && (
            <button
              className="view-all-button"
              onClick={() => {
                handleViewAll("webinar");
              }}
            >
              View All
            </button>
          )}
        </div>
      )}
      <div className="webinar-container">
        <div className="meetings">
          {webinarsdata &&
            webinarsdata.map((webinarItem, index) => (
              <div
                key={index}
                className="meeting-card"
                style={{ width: "32%" }}
              >
                <img
                  src={webinarItem.thumbnail_url}
                  alt="Speaker"
                  className="speaker-image"
                />
                <div className="meeting-header">
                  <span>{webinarItem.module_name}</span>
                </div>
                <div className="meeting-info">
                  <p>
                    <strong>Date:</strong>{" "}
                    {new Date(webinarItem.startdate_time).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Time:</strong>{" "}
                    {new Date(webinarItem.startdate_time).toLocaleTimeString()}
                  </p>
                  <p>
                    <strong>Speaker:</strong> {webinarItem.speaker_name}
                  </p>
                  <p>
                    <strong>Specialty:</strong> {webinarItem.speaker_details}
                  </p>
                </div>
                <button className="view-details-btn">View Details</button>
              </div>
            ))}
        </div>
      </div>
      {newsfeeddata.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              News Feed
            </span>
          </div>
          {modulesdata.length > 0 && (
            <button
              className="view-all-button"
              onClick={() => {
                handleViewAll("newsfeed");
              }}
            >
              View All
            </button>
          )}
        </div>
      )}
      <div className="newsfeed-cards">
        {newsfeeddata &&
          newsfeeddata.map((newsItem, index) => (
            <div className="card news-card" key={index}>
              <img
                className="news-thumbnail"
                src={newsItem.thumbnail_url}
                alt={newsItem.module_name}
              />
              <div className="news-content">
                <span className="news-title">{newsItem.name}</span>
                <div className="news-description-container">
                  <p className="news-description">
                    {expandedNews[index]
                      ? newsItem.descrption
                      : `${newsItem.short_descrption.slice(0, 60)}...`}
                    <button
                      className="read-more-button"
                      onClick={() => toggleReadMore(index)}
                    >
                      {expandedNews[index] ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="news-footer">
                  <div className="like-button" onClick={onclickLike}>
                    <div>
                      <BiLike size={20} />
                    </div>
                    <span>Like</span>
                  </div>
                  <div className="share-button" onClick={onclickshare}>
                    <div>
                      <IoShareSocialOutline size={20} />
                    </div>
                    <span>Share</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {newsfeeddata.length === 0 && <div>No Newsfeed Data to show</div>}
      </div>
      <div>
        <BannerTwo Bannerdata={Bannerdata} />
      </div>
      <div>
        {modulesdata.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                Modules
              </span>
            </div>
            {modulesdata.length > 0 && (
              <button
                className="view-all-button"
                onClick={() => {
                  handleViewAll("modules");
                }}
              >
                View All
              </button>
            )}
          </div>
        )}
        <div className="modules-container">
          <div className="modules-grid">
            {modulesdata &&
              modulesdata.map((module, index) => (
                <div
                  key={index}
                  className={`module-card ${
                    module.access_type === "Exclusive" ? "exclusive" : ""
                  }`}
                >
                  {module.access_type === "Exclusive" && (
                    <span className="exclusive-badge">Only For You</span>
                  )}
                  <img
                    src={
                      module.thumbnail_url || "https://via.placeholder.com/150"
                    }
                    alt={`${module.module_title} Thumbnail`}
                    className="module-thumbnail"
                  />
                  <div style={{ minHeight: "30px" }}>
                    <h4>{module.module_name}</h4>
                  </div>
                  <div style={{ minHeight: "86px" }}>
                    <p style={{ padding: "10px" }}>
                      {expandedNews[index]
                        ? module.module_description
                        : `${module.module_description.slice(0, 50)}...`}
                      <button
                        className="read-more-button-main"
                        onClick={() => toggleReadMore(index)}
                      >
                        {expandedNews[index] ? "Read Less" : "Read More"}
                      </button>
                    </p>
                  </div>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: "40%" }}></div>
                  </div>
                  <div className="module-footer">
                    <div>
                      <FaClock className="icon" />
                      {/* {module.module_type} */}
                    </div>
                    <div>
                      <FaClock className="icon" />
                      {module.module_type}
                    </div>
                  </div>
                  <div style={{ marginBottom: "15%" }}>
                    <button
                      className="enroll-button"
                      onClick={() => {
                        handleViewAll("modules", module.module_id);
                      }}
                    >
                      Enroll Now
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {coursedata.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                Courses
              </span>
            </div>
            <button
              className="view-all-button"
              onClick={() => {
                handleViewAll("course");
              }}
            >
              View All
            </button>
          </div>
        )}
        <div className="modules-container">
          <div className="modules-grid">
            {coursedata.map((course, index) => (
              <div key={index} className="module-card">
                <img
                  src={
                    course.thumbnail_url || "https://via.placeholder.com/150"
                  }
                  alt={`${module.module_name} Thumbnail`}
                  className="module-thumbnail"
                />
                <div style={{ minHeight: "30px" }}>
                  <h4>{course.module_name}</h4>
                </div>
                <div style={{ minHeight: "100px" }}>
                  <p className="news-description-main">
                    {expandedNews[index]
                      ? course.course_descrption
                      : `${course.course_descrption.slice(0, 50)}...`}
                    <button
                      className="read-more-button-main"
                      onClick={() => toggleReadMore(index)}
                    >
                      {expandedNews[index] ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="progress-bar">
                  <div className="progress" style={{ width: "40%" }}></div>
                </div>
                <div className="module-footer">
                  <div>
                    <FaClock className="icon" />
                    {/* {module.module_type} */}
                  </div>
                  <div>
                    <FaClock className="icon" />
                    {course.module_type}
                  </div>
                </div>
                <div style={{ marginBottom: "15%" }}>
                  <button className="enroll-button">Enroll Now</button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div> 
                    <BannerTwo Bannerdata={Bannerdata} />
                </div> */}
        <Modal isOpen={isModalOpen} onClose={cancelLogout}>
          <p style={{ fontWeight: "bold" }}>Please Login or Register</p>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10%" }}
          >
            <button
              style={{ width: "50%" }}
              className="logout-yes-btn"
              onClick={() => {
                onclickbutton("login");
              }}
            >
              Login
            </button>
            <button
              style={{ width: "50%" }}
              className="logout-no-btn"
              onClick={() => {
                onclickbutton("register");
              }}
            >
              Register
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const newsFeedData = state.newsfeed.newsFeed.data;
  // console.log('statheader',state.login)
  return {
    newsFeed: newsFeedData,
    Bannerdata: state.login.banner.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newsfeedrequest: (params) => dispatch(newsfeedrequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
