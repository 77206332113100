import { connect } from "react-redux";
import { modulerequest, Getmodulerequest, VideoupdateRequest } from "./action";
import Module from "./Module";
import { withNavigation } from "../../common/withNavigation";
import {Nextsteprequest} from '../Test/action'

const mapStateToProps = (state) => {
  const { module } = state;
  // console.log('NextstepResult===>', state.Test.NextstepResult)
  return {
    ...module,
    ...state.Test,
    modules: module.module.data,
    fecheddata: module.fetchdata,
    usermodule: module.getModule.data,
    videostatus : module.videoUpdate,
    NextstepResult:state.Test.NextstepResult,
    isvideoLoding : module.isgetmoduleLoding
    // isLoading: module.isLoading,
    // error: module.error,
    // fetchdata: module.fetchdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modulerequest: () => dispatch(modulerequest()),
    Getmodulerequest: (params) => dispatch(Getmodulerequest(params)),
    VideoupdateRequest: (params) => (dispatch(VideoupdateRequest(params))),
    NextStepRequest:(params) =>(dispatch(Nextsteprequest(params)))
  };
};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Module)
);
