import {
  MODULE_FETCH_SUCCESS,
  MODULE_REQUEST,
  MODULE_FETCH_FAILURE,
  GET_MODULE_REQUEST,
  GET_MODULE_SUCCESS,
  GET_MODULE_FAILURE,
  VIDEO_DURATION_UPDATE_REQUEST,
  VIDEO_DURATION_UPDATE_SUCCESS,
  VIDEO_DURATION_UPDATE_FAILURE,
} from "./actionType";

export const modulerequest = (params) => ({
  type: MODULE_REQUEST,
  payload: params,
});

export const modulefetchsuccess = (data) => ({
  type: MODULE_FETCH_SUCCESS,
  payload: data,
});

export const modulefetchfailure = (error) => ({
  type: MODULE_FETCH_FAILURE,
  payload: error,
});

export const Getmodulerequest = (params) => ({
  type: GET_MODULE_REQUEST,
  payload: params,
});

export const Getmodulesuccess = (data) => ({
  type: GET_MODULE_SUCCESS,
  payload: data,
});

export const Getmodulefailure = (error) => ({
  type: GET_MODULE_FAILURE,
  payload: error,
});

export const VideoupdateRequest = (params) => ({
  type: VIDEO_DURATION_UPDATE_REQUEST,
  payload: params,
});

export const VideoupdateSuccess = (data) => ({
  type: VIDEO_DURATION_UPDATE_SUCCESS,
  payload: data,
});

export const VideoupdateFailure = (error) => ({
  type: VIDEO_DURATION_UPDATE_FAILURE,
  payload: error,
});
