import {
  MODULE_REQUEST,
  MODULE_FETCH_SUCCESS,
  MODULE_FETCH_FAILURE,
  GET_MODULE_REQUEST,
  GET_MODULE_SUCCESS,
  GET_MODULE_FAILURE,
  VIDEO_DURATION_UPDATE_REQUEST,
  VIDEO_DURATION_UPDATE_SUCCESS,
  VIDEO_DURATION_UPDATE_FAILURE
} from "./actionType";

const initialState = {
  fetchdata: false,
  module: { data: [] },
  error: null,
  isLoading: false,

  isgetmoduleLoding: false,
  getModule: [],
  getModuleError: null,

  isVideoupdateLoading:false,
  videoUpdate:[],
  videoError:null,
};

const modulereducer = (state = initialState, action) => {
  switch (action.type) {
    case MODULE_REQUEST:
      return { ...state, isLoading: true };
    case MODULE_FETCH_SUCCESS:
      return {
        ...state,
        fetchdata: true,
        module: action.payload,
        error: null,
        isLoading: false,
      };
    case MODULE_FETCH_FAILURE:
      return {
        ...state,
        fetchdata: false,
        error: action.payload,
        isLoading: false,
      };
      case GET_MODULE_REQUEST:
      return {
        ...state,
        isgetmoduleLoding: true
    };
    case GET_MODULE_SUCCESS:
      return {
        ...state,
        isgetmoduleLoding: false,
        getModule: action.payload,
        getModuleError: null,
      };
    case GET_MODULE_FAILURE:
      return {
        ...state,
        getModuleError: action.payload,
        isgetmoduleLoding: false,
      };
      case VIDEO_DURATION_UPDATE_REQUEST:
      return {
        ...state,
        isVideoupdateLoading: true
    };
    case VIDEO_DURATION_UPDATE_SUCCESS:
      return {
        ...state,
        isVideoupdateLoading: false,
        videoUpdate: action.payload,
        videoError: null,
      };
    case VIDEO_DURATION_UPDATE_FAILURE:
      return {
        ...state,
        videoError: action.payload,
        isVideoupdateLoading: false,
      };
    default:
      return state;
  }
};

export default modulereducer;
