import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../Style/Modules.css";

function Modules({
  modules,
  onclickendroll,
  usermodule,
  expandedNews,
  toggleReadMore,
  handleVideoClick,
  currentVideo,
  handleCloseVideo,
  handleTimeUpdate,
  handleVideoLoadedMetadata,
  moduleStatus,
  videopause,
  moduleVideoStatus,
  isVideoPlaying,
  handleNext,
  handlePrevious,
  videostatus,
  NextstepResult,
  isvideoLoding,
}) {
  const [modulesData, setModulesData] = useState([]);
  const [durations, setdurations] = useState(0);
  const [autoplay, setautoplay] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  // console.log('videostatus------>',videostatus)

  useEffect(() => {
    if (modules !== undefined) {
      setModulesData(modules);
    }
    if (moduleVideoStatus === 1) {
      setdurations(0);
    } else {
      setdurations(videopause);
    }
  }, [modules, videopause, moduleVideoStatus]);

  useEffect(() => {
    if (usermodule) {
      if (
        usermodule[0].video_type === "2" ||
        usermodule[0].video_type === "1"
      ) {
        handleVideoClick(0);
        setautoplay(true);
      } else {
        setautoplay(true);
      }
    }
  }, [usermodule, handleVideoClick]);

  useEffect(() => {
    const setVideoTime = () => {
      if (videoRef.current && durations) {
        videoRef.current.currentTime = durations;
      }
    };
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", setVideoTime);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", setVideoTime);
      }
    };
  }, [durations, currentVideo]);

  // if(currentVideo === null){
  //   return <div>Loading...</div>
  // }

  const onclicknextstep = () => {
    // console.log('NextstepResult', NextstepResult)
    if (NextstepResult) {
      console.log("nextstep", NextstepResult.data);
      if (NextstepResult.data.length > 0) {
        navigate(
          `${NextstepResult.data[0].route}?id=${NextstepResult.data[0].contentId}`
        );
      } else {
        alert("You Completed all task");
      }
    }
  };

  if (isvideoLoding) {
    return <div>Loading....</div>;
  }

  return (
    <>
      <div className="modules-container">
        {!usermodule || usermodule.length === 0 ? (
          <div className="modules-grid">
            {modulesData.map((module, index) => (
              <div key={index} className="module-card">
                <img
                  src={
                    module.thumbnail_url || "https://via.placeholder.com/150"
                  }
                  alt={`${module.module_title} Thumbnail`}
                  className="module-thumbnail"
                />
                <div style={{ minHeight: "30px" }}>
                  <h4>{module.name}</h4>
                </div>
                <div style={{ minHeight: "86px" }}>
                  <p>
                    {expandedNews[index]
                      ? module.module_description
                      : `${module.module_description.slice(0, 30)}...`}
                    <button
                      className="read-more-button-main"
                      onClick={() => toggleReadMore(index)}
                    >
                      {expandedNews[index] ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <button
                  className="enroll-button"
                  onClick={() => {
                    onclickendroll(module.id);
                  }}
                >
                  Enroll Now
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {videostatus.Completed === 1 && (
              <button
                style={{
                  backgroundColor: "white",
                  border: "none",
                  borderBottom: "2px solid blue",
                }}
                onClick={onclicknextstep}
              >
                Next Step
              </button>
            )}
            {currentVideo && (
              <div className="video-modal">
                <div
                  style={{
                    marginBottom: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <button
                    className="next-video-button"
                    onClick={handleCloseVideo}
                  >
                    Back
                  </button> */}
                  <div>
                    <p style={{ fontWeight: "bold" }}>
                      {usermodule && usermodule[0].content_name}
                    </p>
                  </div>
                  <div></div>
                </div>
                <div className="video-modal-content">
                  <video
                    controls={moduleVideoStatus === 1}
                    // controls
                    autoPlay={autoplay}
                    className="video-player"
                    onEnded={handleCloseVideo}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleVideoLoadedMetadata}
                    ref={videoRef}
                    // currentTime={videopause}
                  >
                    <source src={currentVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {(usermodule && usermodule[0].video_type === "2") ||
                      (usermodule[0].video_type === "1" &&
                        usermodule.map((module, index) => {
                          return (
                            <div key={index}>
                              <h4 className="module-title">
                                {module?.name || "Module"}
                              </h4>
                              {/* <button
                    className="play-video-button"
                    onClick={() => handleVideoClick(index)}
                  >
                    Play Video
                  </button> */}
                            </div>
                          );
                        }))}
                    <div>
                      {(usermodule[0].module_details.length > 0 &&
                        usermodule[0].video_type === "2") ||
                        (usermodule[0].video_type === "1" &&
                          moduleVideoStatus === 1 && (
                            <>
                              {moduleVideoStatus === 1 && (
                                <button
                                  className="next-video-button"
                                  onClick={handlePrevious}
                                >
                                  Previous
                                </button>
                              )}
                              {moduleVideoStatus === 1 && (
                                <button
                                  className="next-video-button"
                                  onClick={handleNext}
                                >
                                  Next
                                </button>
                              )}
                              {/* Add the "Previous" button */}
                              {/* {moduleVideoStatus === 1 && (
                            <button
                              className="previous-video-button"
                              onClick={handlePrevious}
                            >
                              Previous
                            </button>
                          )} */}
                            </>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="modules-wrapper">
          {usermodule &&
            usermodule[0].video_type === "3" &&
            usermodule.map((module, index) => (
              <div key={index} className="module-row">
                {module.module_details.map((modu, i) => (
                  <div key={i} className="module-card">
                    <div className="module-image">
                      <img
                        src={
                          modu.thumbnail || "https://via.placeholder.com/150"
                        }
                        alt={`${modu?.name || "Module"} Thumbnail`}
                        className="module-image-content"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className="module-title">
                          {modu?.module_title || "Module"}
                        </span>
                        <div>
                          <span>{`Durations : ${modu?.duration}`}</span>
                        </div>
                      </div>
                      <button
                        className={`play-video-button ${
                          modu.is_locked === 1 ? "disabled" : ""
                        }`}
                        onClick={() => handleVideoClick(i)}
                        disabled={modu.is_locked === 1}
                      >
                        Play Video
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Modules;
