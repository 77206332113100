import React, { Component } from "react";
import Modules from "../../Component/Pages/Modules";
// import Modal from "../../common/Modal";
// import { FaClock } from "react-icons/fa";
import "../../Component/Style/Modules.css";

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmodal: false,
      modulesData: [],
      expandedNews: {},
      usermoduleData: [],
      currentVideo: null,
      currentModuleIndex: 0,
      currentVideoIndex: 0,
      watchTime: 0,
      videoUrl: "",
      isVideoPlaying: false,
      intervalId: null,
      videoDuration: 0,
      Id: 0,
      moduleStatus: "IN PROGRESS",
      videopause: 0,
      moduleVideoStatus: 0,
      Nextbuttonshow: false,
      completeStatus : 0
    };
  }

  componentDidMount() {
    const { modulerequest, location, Getmodulerequest,NextStepRequest } = this.props;
    const data = location.state;
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    // console.log("componentDidMount-->", id);
    if (id) {
      this.setState({
        Id: id,
      });
      Getmodulerequest({
        id,
      });
    }
    if (data && data.data) {
      const contentId = data.data.contentId;
      if (contentId !== undefined) {
        Getmodulerequest({
          id: contentId,
        });
      }
    }
    if (data && data.item) {
      const contentId = data.item.contentId;
      const program_id = data.item.program_id
      if (contentId !== undefined) {
        Getmodulerequest({
          id: contentId,
          program_id,
        });
      }
      if(program_id){
        NextStepRequest({
          id: program_id,
        })
      }
    }
    modulerequest();
  }
  componentDidUpdate(prevProps, prevState) {
    const { usermodule } = this.props;
    const { currentModuleIndex, currentVideoIndex, currentVideo, intervalId } =
      this.state;
    if (currentVideo === null && prevState.currentVideo !== null) {
      const currentModule = usermodule[currentModuleIndex];
      const currentModuleVideos = currentModule?.module_details || [];
      if (currentVideoIndex + 1 < currentModuleVideos.length) {
        const nextVideo = currentModuleVideos[currentVideoIndex + 1].content;
        const nextContentId =
          currentModuleVideos[currentVideoIndex + 1].contentId;
        const Videopause = currentModuleVideos[currentVideoIndex + 1].duration;
        const module_status =
          currentModuleVideos[currentVideoIndex + 1].module_status;
        this.setState({
          currentVideo: nextVideo,
          currentVideoIndex: currentVideoIndex + 1,
          currentContentId: nextContentId,
          videopause: Videopause,
          moduleVideoStatus: module_status,
        });
      } else {
        const nextModuleIndex = currentModuleIndex + 1;
        if (nextModuleIndex < usermodule.length) {
          const nextModule = usermodule[nextModuleIndex];
          const nextModuleVideos = nextModule.module_details || [];
          if (nextModuleVideos.length > 0) {
            this.setState({
              currentModuleIndex: nextModuleIndex,
              currentVideoIndex: 0,
              currentVideo: nextModuleVideos[0].content,
              currentContentId: nextModuleVideos[0].contentId,
            });
          } else {
            // alert("You have completed all videos!");
            this.handleVideoComplete();
            this.setState({ currentVideo: null });
          }
        } else {
          // alert("You have completed all videos!");
          this.setState({ currentVideo: null });
        }
      }
    }
    if (this.state.isVideoPlaying && !intervalId) {
      const newIntervalId = setInterval(() => {
        this.handleSendModuleData();
      }, 6000);
      this.setState({ intervalId: newIntervalId });
    }
    if (!this.state.isVideoPlaying && intervalId) {
      clearInterval(intervalId);
      this.setState({ intervalId: null });
    }
    if (!this.state.isVideoPlaying && intervalId) {
      this.clearProgressInterval();
    }
    // if(prevProps.videostatus !== videostatus){
    //   if(videostatus.Completed === 1){
    //     this.setState({
    //       Nextbuttonshow:true
    //     })
    //   }
    // }
  }

  componentWillUnmount() {
    this.clearProgressInterval();
  }

  clearProgressInterval = () => {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
      this.setState({ intervalId: null });
    }
  };

  toggleReadMore = (index) => {
    this.setState((prevState) => ({
      expandedNews: {
        ...prevState.expandedNews,
        [index]: !prevState.expandedNews[index],
      },
    }));
  };

  handleVideoClick = (moduleIndex) => {
    // console.log('usermodele', moduleIndex)
    const { usermodule } = this.props;
    const selectedModule = usermodule[0];
    if (selectedModule && selectedModule.module_details.length > 0) {
      const firstVideo = selectedModule.module_details[moduleIndex];
      // console.log("selectedModule==>", firstVideo.duration);
      this.setState({
        currentModuleIndex: moduleIndex,
        currentVideoIndex: 0,
        currentVideo: firstVideo.content,
        currentContentId: firstVideo.contentId,
        isVideoPlaying: true,
        moduleVideoStatus: firstVideo.module_status,
        videopause: firstVideo.duration,
      });
    } else {
      console.error("Selected module details are missing.");
    }
  };

  handleCloseVideo = () => {
    this.setState({ currentVideo: null, isVideoPlaying: false });
    this.clearProgressInterval();
  };

  // handleNext = () =>{
  //   this.setState({
  //     currentVideo : null,
  //   })
  // }

  handleTimeUpdate = (event) => {
    const { currentTime } = event.target;
    const { videoDuration } = this.state;
    this.setState({ watchTime: Math.floor(currentTime) });
    // const minutes = Math.floor(currentTime / 60);
    // const seconds = Math.floor(currentTime % 60); `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
    const watchduration = Math.floor(currentTime);
    this.setState({ watchTime: watchduration });
    if (watchduration >= videoDuration) {
      this.handleVideoComplete();
    }
  };
  handleVideoComplete = () => {
    const {
      currentContentId,
      watchTime,
      videoDuration,
      Id,
      currentModuleIndex,
      currentVideoIndex,
    } = this.state;
    const { VideoupdateRequest, usermodule } = this.props;
    if (watchTime >= videoDuration) {
      console.log('completed');
      const params = {
        contentId: currentContentId,
        status: "Completed",
        duration: watchTime,
        Id,
      };
      this.setState({
        completeStatus: 1
      })
      // console.log("Video Completed! Content ID:", params);
      VideoupdateRequest(params);
      const currentModule = usermodule[currentModuleIndex];
      const isLastVideo =
        currentVideoIndex + 1 === (currentModule?.module_details || []).length;
      const isLastModule = currentModuleIndex + 1 === usermodule.length;
      if (isLastVideo && isLastModule) {
        // alert("You have completed all videos!");
        this.clearProgressInterval();
        this.setState({ currentVideo: null, isVideoPlaying: false });
      } else {
        console.log("handleVideoComplete->", "false");
        // alert("You have not completed all videos!");
        // this.moveToNextVideoOrModule();
      }
    }
  };

  onclickendroll = (id) => {
    const {navigate, Getmodulerequest} = this.props
    if(id){
      navigate(`?id=${id}`)
      Getmodulerequest({
        id,
      });
    }
  };

  closemodal = () => {
    this.setState({ showmodal: false });
  };

  handleSendModuleData = () => {
    const { watchTime, currentContentId, Id } = this.state;
    const { VideoupdateRequest } = this.props;
    // const selectedModule = usermodule[currentModuleIndex];
    // const moduleContentId = selectedModule?.module_details[0]?.contentId;

    // console.log("currentContentId: ", currentContentId);
    // console.log("Selected Module: ", selectedModule);
    console.log('inprogresss');
    const params = {
      contentId: currentContentId,
      status: "In progress",
      duration: watchTime,
      Id,
    };
    // console.log("Video Progress!:", params);
    VideoupdateRequest(params);
  };
  handleVideoLoadedMetadata = (event) => {
    const videoDuration = event.target.duration;
    // const minutes = Math.floor(videoDuration / 60);
    // const seconds = Math.floor(videoDuration % 60);
    this.setState({
      videoDuration: Math.floor(videoDuration),
    });
  };

  handleNext = () => {
    const { currentModuleIndex, currentVideoIndex } = this.state;
    const { usermodule } = this.props;
    const currentModule = usermodule[0];
    const currentModuleVideos =
      currentModule?.module_details[currentModuleIndex] || [];
    if (currentVideoIndex + 1 < currentModuleVideos.length) {
      const nextVideo = currentModuleVideos[currentVideoIndex + 1];
      // console.log('nextvideo', currentModuleIndex + 1 < usermodule.length)
      this.setState({
        currentVideo: nextVideo.content,
        currentVideoIndex: currentVideoIndex + 1,
        videopause: nextVideo.duration,
        moduleVideoStatus: nextVideo.module_status,
      });
    } else if (currentModuleIndex + 1 < usermodule.length) {
      const nextModule = usermodule[currentModuleIndex];
      const nextModuleVideos = nextModule?.module_details || [];
      if (nextModuleVideos.length > 0) {
        this.setState({
          currentModuleIndex: currentModuleIndex + 1,
          currentVideoIndex: 0,
          currentVideo: nextModuleVideos[0].content,
          videopause: nextModuleVideos[0].duration,
          moduleVideoStatus: nextModuleVideos[0].module_status,
        });
      } else {
        this.handleVideoComplete();
      }
    } else {
      // console.log('nextvideo', currentModuleIndex + 1 < usermodule[0].module_details.length)
      // alert("You have completed all videos!");
      this.setState({
        currentVideo: null,
        isVideoPlaying: false,
      });
    }
  };
  handlePrevious = () => {
    const { currentModuleIndex, currentVideoIndex } = this.state;
    const { usermodule } = this.props;
    
    // Check if there is a previous video in the current module
    if (currentVideoIndex > 0) {
      const previousVideo = usermodule[currentModuleIndex].module_details[currentVideoIndex - 1];
      this.setState({
        currentVideo: previousVideo.content,
        currentVideoIndex: currentVideoIndex - 1,
        videopause: previousVideo.duration,
        moduleVideoStatus: previousVideo.module_status,
      });
    } else if (currentModuleIndex > 0) {
      const previousModule = usermodule[currentModuleIndex - 1];
      const previousModuleVideos = previousModule?.module_details || [];
      const lastVideoInPreviousModule = previousModuleVideos[previousModuleVideos.length - 1];
  
      this.setState({
        currentModuleIndex: currentModuleIndex - 1,
        currentVideoIndex: previousModuleVideos.length - 1,
        currentVideo: lastVideoInPreviousModule.content,
        videopause: lastVideoInPreviousModule.duration,
        moduleVideoStatus: lastVideoInPreviousModule.module_status,
      });
    }
  };
  

  render() {
    const {
      expandedNews,
      currentVideo,
      moduleStatus,
      watchTime,
      videopause,
      moduleVideoStatus,
      isVideoPlaying,
      // Nextbuttonshow,
      Id,
      completeStatus
    } = this.state;
    const { modules, usermodule,videostatus, NextstepResult, isvideoLoding } = this.props;

    // console.log('NextstepResult', NextstepResult)

    return (
      <div style={{ width: "100%" }}>
        <Modules
          modules={modules}
          onclickendroll={this.onclickendroll}
          usermodule={usermodule}
          expandedNews={expandedNews}
          toggleReadMore={this.toggleReadMore}
          handleVideoClick={this.handleVideoClick}
          currentVideo={currentVideo}
          handleCloseVideo={this.handleCloseVideo}
          handleTimeUpdate={this.handleTimeUpdate}
          handleVideoLoadedMetadata={this.handleVideoLoadedMetadata}
          moduleStatus={moduleStatus}
          watchTime={watchTime}
          videopause={videopause}
          moduleVideoStatus={moduleVideoStatus}
          isVideoPlaying={isVideoPlaying}
          handleNext={this.handleNext}
          handlePrevious={this.handlePrevious}
          videostatus={videostatus}
          NextstepResult={NextstepResult}
          isvideoLoding={isvideoLoding}
          Id={Id}
          completeStatus={completeStatus}
        />
        {/* <Modal isOpen={showmodal} onClose={this.closemodal}>
          <div style={{ width: "100%" }}>
            {isVideoPlaying ? (
              <video
                width="100%"
                controls
                autoPlay
                onPlay={() => this.setState({ isVideoPlaying: true })}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div>
                <p>Click below to play the video:</p>
                <button onClick={() => this.setState({ isVideoPlaying: true })}>
                  Play Video
                </button>
              </div>
            )}
          </div>
        </Modal> */}
      </div>
    );
  }
}

export default Module;
